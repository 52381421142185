import React, {useState} from 'react';
import Header from "./Header";
import IndividualOrders from "./individualOrders";
import Basket from "./Basket";
import Picture1 from "../icons/Подача блюд/Picture1.png"
import Picture2 from "../icons/Подача блюд/Picture2.png"
import Picture3 from "../icons/Подача блюд/Picture3.png"
import Picture4 from "../icons/Подача блюд/Picture4.png"
import Picture5 from "../icons/Подача блюд/Picture5.png"
import Picture6 from "../icons/Подача блюд/Picture6.png"
import NavComponent from "./components_nav/NavComponent";
import Contacts from "./Contactsю";
import AboutUs from "./AboutUs";
import BlogNavComponent from "./component_blog/Blog";
// import  from "@types/react";
import Picture7 from "../icons/Подача блюд/Picture7.png"
import Picture8 from "../icons/Сервировка стола/Ящик для приборов.jpg"
import Picture9 from "../icons/Сервировка стола/Салфетница.jpg"
import Picture10 from "../icons/Сервировка стола/Набор для специй и салфетница.jpg"
import Picture11 from "../icons/Сервировка стола/Стакан с приборами.jpg"
import Picture19 from "../icons/Сервировка стола/Набор для специй.png"
//
import Picture12 from "../icons/Ценники/Ценники.png"
//
import Picture13 from "../icons/Торцевые доски/Набор торцевых досок.png"
import Picture14 from "../icons/Торцевые доски/Набор торцевых досок малых.png"
//
import Picture15 from "../icons/Чайные станции/Чайная станция mini.png"
import Picture16 from "../icons/Чайные станции/Чайная станция №1.png"
//
import Picture17 from "../icons/Мебель/Штендер.png"
import Picture18 from "../icons/Мебель/Штендер 1.png"
//
import Picture20 from "../icons/Таблички/platestags.jpg"




const Serving = [
    {
        // id: 0,
        name: "Набор для специй",
        series: "серия",
        TextAnnouncement: "Набор для специй из дуба. Включает в себя подставку и три емкости: для зубочисток, соли, перца. ",
        price: 1690,
        totalPrice: 1690,
        about: "Стильный и универсальный набор для специй. Изготовлен из дуба, обработан профессиональными маслами и карнаубским воском.Общий размер:   Размер каждой ёмкости:",
        src: Picture19 ,
        count: 1
    },
    {
        // id: 1,
        name: "Набор для специй и салфетница",
        series: "серия",
        TextAnnouncement: "Универсальный набор с отсеками для: салфеток, солонки и перечницы. По желанию, отсеков можно сделать больше.",
        price: 1690,
        totalPrice: 1690,
        about: "",
        src: Picture10 ,
        count: 1
    },
    {
        // id: 2,
        name: "Салфетница из дуба",
        series: "серия",
        TextAnnouncement: "Универсальная салфетница – кубик. Отлично впишется в любой интерьер",
        price: 1150,
        totalPrice: 1150,
        about: "Размеры: 14*14*10 см.\n" +
            "Обработана профессиональными льняными маслами и карнаубским воском.   \n" +
            "Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт\n",
        src: Picture9,
        count: 1
    },
    {
        // id: 3,
        name: "Ящик для приборов",
        series: "серия 'Простые формы'",
        TextAnnouncement: "",
        price: 1690,
        totalPrice: 1690,
        about: "",
        src: Picture8
        ,
        count: 1
    },
    {
        // id: 4,
        name: "Стакан для приборов",
        series: "серия 'Простые формы'",
        TextAnnouncement: "",
        price: 1690,
        totalPrice: 1690,
        about: "",
        src: Picture11,
        count: 1
    },
    {
        // id: 5,
        name: "Подставка для приборов из 4х секций",
        series: "серия",
        TextAnnouncement: "",
        price: 1690,
        totalPrice: 1690,
        about: "",
        src: '',
        count: 1
    },

];
const ServingDishes = [
    {
        // id: 0,
        name: "Тарелка Скала",
        series: "серия 'Скала'",
        TextAnnouncement: "Серия 'Скала' - очень атмосферная и небанальная. Края тарелки с интересным узором и легким обжигом. Данная тарелка подойдет для подачи стейков, бургеров, горячих закусок, десертов.",
        price: 1720,
        totalPrice: 1720,
        about: "Серия 'Скала' - стильная и необычная. Шикарно впишется в темные интерьеры, рестораны с уклоном на мясо. Изготовлена из массива дуба, без склейки. Края украшены интересным узором и легким обжигом. Обработана профессиональными льняными маслами и карнаубским воском.Размер тарелки - 22*32 см., можно сделать больше или меньше -  просто перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер во внутреннюю часть  +50р/шт, , увеличить толщину тарелки +350р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца.     + текст который ко всем я писала из файлика ",
        src: Picture1,
        count: 1
    },
    {
        // id: 1,
        name: "Тарелка прямоугольник",
        series: "серия 'Простые формы'",
        TextAnnouncement: "Тарелка для подачи с бортиками. Подойдет для подачи закусок, хлеба, десертов и других блюд прямоугольной формы.",
        price: 1570,
        totalPrice: 1570,
        about: "Универсальная деревянная тарелка с бортиками прямоугольной формы. Впишется в концепцию в любой ресторан. Отлично подойдет для подачи закусок и снеков.Обработана профессиональными льняными маслами и карнаубским воском.Размер тарелки - 28*14 см., можно сделать больше или меньше -  просто перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт, увеличить борт +250р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца.",
        src: Picture2,
        count: 1
    },
    {
        // id: 2,
        name: "Тарелка круг",
        series: "серия 'Простые формы'",
        TextAnnouncement: "Универсальная тарелка с бортиками. Будет удобна для подачи любых видов блюд.",
        price: 1620,
        totalPrice: 1620,
        about: "Универсальная тарелка с бортиками. Будет удобна для подачи любых видов блюд, будь то салат или второе блюдо. Идеально подойдет под концепцию любого ресторана.Изготовлена из массива дуба, без склейки. Обработана профессиональными льняными маслами и карнаубским воском.Диаметр тарелки - 24 см., можно сделать больше или меньше -  просто перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт, увеличить борт  +250р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца. + текст который ко всем я писала из файлика",
        src: Picture3,
        count: 1
    },
    {
        // id: 3,
        name: "Тарелка овал",
        series: "серия 'Простые формы'",
        TextAnnouncement: "Универсальная тарелка с бортиками. Будет удобна для подачи любых видов блюд .",
        price: 1620,
        totalPrice: 1620,
        about: "Универсальная тарелка с бортиками. Будет удобна для подачи любых видов блюд. Идеально подойдет под концепцию любого ресторана.Изготовлена из массива дуба, без склейки. Обработана профессиональными льняными маслами и карнаубским воском.Размер тарелки - 31*21 см., можно сделать больше или меньше -  просто перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт, увеличить борт (толщину тарелки) +250р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца.   + текст который ко всем я писала из файлика",
        src: Picture4,
        count: 1
    },
    {
        // id: 4,
        name: "Доска для подачи круглая большая",
        series: "серия",
        TextAnnouncement: "Доска идеальна для подачи пиццы, сета роллов, ассорти закусок и других блюд на компанию.",
        price: 1880,
        totalPrice: 1880,
        about: "Круглая доска с желобом. Идеальна для подачи пиццы, торта, сета роллов и других блюд на компанию. Также, отлично подойдет для сервировки тарелки.Изготавливается из ламелей дуба (со склейкой).   Обработана профессиональными льняными маслами и карнаубским воском.Диаметр: 30 см. По желанию можно сделать диаметр больше или меньше. Для этого перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца.  + текст который ко всем я писала из файлика",
        src: Picture5,
        count: 1
    },
    {
        // id: 6,
        name: "Доска для подачи прямоугольних с желобом",
        series: "серия",
        TextAnnouncement: "Доска для подачи стейков, бургеров. Желоб предназначен для стекания сока. Можно использовать для сервировки под тарелку.",
        price: 1680,
        totalPrice: 1680,
        about: "Незаменимая доска для любого ресторана. Идеальна как для самостоятельной подачи блюд (стейки, закуски, бургеры), так и для сервировки под тарелку.Изготовлена из массива дуба, склейка профессиональным клеем (допустим к контакту с пищей). Обработана профессиональными льняными маслами и карнаубским воском.Размер доски - 24*32 см., можно сделать больше или меньше, убрать желоб, добавить ручки и т.д. -  просто перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт, толщину доски +250р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца.     + текст который ко всем я писала из файлика",
        src: Picture7,
        count: 1
    },
    {
        // id: 5,
        name: "Доска для подачи круглая с вращающимся механизмом",
        series: "серия",
        TextAnnouncement: "Доска идеальна для подачи пиццы, сета роллов, ассорти закусок и других блюд на компанию. Изюминка данной доски - вращающийся механизм. Не придется дотягиваться до блюда, достаточно развернуть доску к себе.",
        price: 2600,
        totalPrice: 2600,
        about: "Круглая доска с вращающимся механизмом. Идеальна для подачи пиццы, торта, сета роллов и других блюд на компанию.Изготавливается из ламелей дуба (со склейкой).   Обработана профессиональными льняными маслами и карнаубским воском.Диаметр: 30 см. По желанию можно сделать диаметр больше или меньше. Для этого перейдите в раздел 'Индивидуальные заказы' и напишите нам.Предлагается в классическом светлом варианте, по желанию можно добавить: колер +50р/шт, обжиг + 100р/шт.Пожалуйста, оформляйте заказ заранее. Помимо изготовления, тарелке необходимо 'напитаться маслом'. Масло должно проникнуть в поры дерева и полимеризоваться, это не быстрый процесс. Ваши тарелки прослужат очень долго, если данный этап будет пройден до конца.  + текст который ко всем я писала из файлика",
        src: Picture6,
        count: 1
    },
];
const TeaStationsTrays = [
    {
        // id: 0,
        name: "Чайная станция №1",
        series: "",
        TextAnnouncement: "Станция с отсеками для: чайника, чая, сахара, ложечек, капсульной кофемашины, капсул, стаканов.",
        price: 3150,
        totalPrice:3150,
        count: 1,
        about: "Представлен вариант из бука с обработкой прозрачным льняным маслом, карнаубским воском.\n" +
            "Возможно изготовление из ясеня +200р., из дуба +400р., покрытие в другой цвет, нанесение логотипа.\n" +
            "Согласитесь, большинство людей снимают «room тур» при заселении в отель. Как раз многие обращают внимание на различные детали. Так вот за такую деталь отелю могут поставить большой плюсик. К тому же, такая чайная станция отлично подчеркнет интерьер номера. Это стильно, современно, с ноткой уюта\n",
        src: Picture16
    },
    {
        // id: 1,
        name: "Чайная станция mini",
        series: "",
        TextAnnouncement: "Станция с отсеками для: чайника, чая и кофе, сахара и стаканов",
        price: 2850,
        totalPrice:2850,
        count: 1,
        about: "Представлен вариант из бука с обработкой прозрачным льняным маслом, карнаубским воском.\n" +
            "Возможно изготовление из ясеня +200р., из дуба +400р., покрытие в другой цвет, нанесение логотипа.\n" +
            "Согласитесь, большинство людей снимают «room тур» при заселении в отель. Как раз многие обращают внимание на различные детали. Так вот за такую деталь отелю могут поставить большой плюсик. К тому же, такая чайная станция отлично подчеркнет интерьер номера. Это стильно, современно, с ноткой уюта.\n",
        src: Picture15
    }
];
const PriceTags = [
    {
        // id: 0,
        name: "Набор ценникодержателей 100*30*20, 6 шт",
        series: "",
        TextAnnouncement: "Стильные, экологичные ценникодержатели из натурального дерева, массива дуба. Покрыты профессиональным льняным маслом. Тщательно отшлифованы. ",
        price: 680,
        totalPrice:680,
        count: 1,
        about: "Наверняка, Вы раздумывали над интерьером, подбирали эстетические и современные решения, использовали различные фишки для влияния на продажи. Такие ценникодержатели только подчеркнут Ваши идеи. Такая, казалось бы мелочь, может влиять на выручку Вашего предприятия. Они подчеркивают и даже повышают ценность товара.\n" +
            "Также, держатели можно использовать для других целей - для визитки на рабочем столе, нумерации столов на свадьбе или другом празднике.\n" +
            "Обратите внимание, что ценникодержатели покрыты бесцветным маслом. Небольшая разница цвета и текстуры - это нормально, это природные характеристики дерева. Невозможно повторить одно изделие с точностью в 100%.\n",
        src: Picture12
    },
    {
        // id: 1,
        name: "Набор ценникодержателей 70*30*20, 6 шт",
        series: "",
        TextAnnouncement: "Стильные, экологичные ценникодержатели из натурального дерева, массива дуба. Покрыты профессиональным льняным маслом. Тщательно отшлифованы. ",
        price: 520,
        totalPrice:520,
        count: 1,
        about: "Наверняка, Вы раздумывали над интерьером, подбирали эстетические и современные решения, использовали различные фишки для влияния на продажи. Такие ценникодержатели только подчеркнут Ваши идеи. Такая, казалось бы мелочь, может влиять на выручку Вашего предприятия. Они подчеркивают и даже повышают ценность товара.\n" +
            "Также, держатели можно использовать для других целей - для визитки на рабочем столе, нумерации столов на свадьбе или другом празднике.\n" +
            "Обратите внимание, что ценникодержатели покрыты бесцветным маслом. Небольшая разница цвета и текстуры - это нормально, это природные характеристики дерева. Невозможно повторить одно изделие с точностью в 100%.\n",
        src: Picture12
    }
];
const EndBoards = [
    {
        // id: 0,
        name: "Набор торцевых досок, дуб/ясень, 35*24 и 30*20 см",
        series: "",
        TextAnnouncement: "С силиконовыми ножками.\n" +
            "Изготовлено из натурального дерева, склейка нетоксичным клеем. Обработка профессиональным льняным маслом и карнаубским воском. Все составы разрешены к контакту с пищей.\n",
        price: 5100,
        totalPrice:5100,
        count: 1,
        about: "Преимущества торцевых досок:\n" +
            "•\tМассивные, устойчивые, удобные;\n" +
            "•\tНожи будут дольше оставаться острыми, благодаря расположению волокон. Волокна древесины расположены перпендикулярно резу, и лезвие ножа будто раздвигает волокна. При использовании обычной доски нож просто перерезает волокна, что приводит к более быстрому затуплению ножа;\n" +
            "•\tПрочная. Твердость торцевой поверхности древесины почти в полтора раза превышает твердость боковой поверхности;\n" +
            "•\tПоверхность дольше сохраняет свой внешний вид, меньше видны порезы от ножа, так как волокна древесины возвращаются в исходное положение. У обычной доски - волокна разрушаются;\n" +
            "•\tТорцевая доска менее подвержена заражению бактериями, поскольку волокна после реза «смыкаются», а покрытие маслом защищает от проникновения бактерий.\n" +
            "А еще, это отличный подарок по любому поводу (на Новый год, 8 Марта, день рождения и др.).\n" +
            "Размеры досок: 35*24 см, 30*20 см.\n" +
            "Подставка в комплекте!\n",
        src: Picture13
    },
    {
        // id: 1,
        name: "Набор торцевых досок малых, дуб/ясень, 18,5*20 см и 22*24 см, с подставкой",
        series: "",
        TextAnnouncement: "С силиконовыми ножками.\n" +
            "Изготовлено из натурального дерева, склейка нетоксичным клеем. Обработка профессиональным льняным маслом и карнаубским воском. Все составы разрешены к контакту с пищей.\n",
        price: 3800,
        totalPrice:3800,
        count: 1,
        about: "Преимущества торцевых досок:\n" +
            "•\tМассивные, устойчивые, удобные;\n" +
            "•\tНожи будут дольше оставаться острыми, благодаря расположению волокон. Волокна древесины расположены перпендикулярно резу, и лезвие ножа будто раздвигает волокна. При использовании обычной доски нож просто перерезает волокна, что приводит к более быстрому затуплению ножа;\n" +
            "•\tПрочная. Твердость торцевой поверхности древесины почти в полтора раза превышает твердость боковой поверхности;\n" +
            "•\tПоверхность дольше сохраняет свой внешний вид, меньше видны порезы от ножа, так как волокна древесины возвращаются в исходное положение. У обычной доски - волокна разрушаются;\n" +
            "•\tТорцевая доска менее подвержена заражению бактериями, поскольку волокна после реза «смыкаются», а покрытие маслом защищает от проникновения бактерий.\n" +
            "А еще, это отличный подарок по любому поводу (на Новый год, 8 Марта, день рождения и др.).\n" +
            "Размеры досок: 18,5*20 см, 22*24 см.\n" +
            "Подставка в комплекте!\n",
        src: Picture14
    },

];
const SignsTags = [
    {
        // id: 1,
        name: "Табличка",
        series: "",
        TextAnnouncement: "Табличка Reserved",
        price: 1300 ,
        totalPrice:1300,
        count: 1,
        about: "",
        src: Picture20
    }
];
const Furniture  = [
    {
        // id: 0,
        name: "Штендер меловой рекламный двухсторонний, 95*60 см",
        series: "",
        TextAnnouncement: "Штендер рекламный, двухсторонний для письма и рисования мелом",
        price: 3100,
        totalPrice:3100,
        count: 1,
        about: "Штендер рекламный, двухсторонний для письма и рисования мелом, изготовлен из массива дерева обработанного специальным составом, сбоку имеется фиксатор для устойчивости-цепочка. Применяется в качестве рекламной стойки выносного типа. Очень легкая, устойчивая и удобная в работе конструкция, позволяет оперативно менять информацию о ценах, акциях, меню, распродажах.\n" +
            "Также, хорошая идея для детской комнаты. Отлично сыграет роль детского мольберта.\n" +
            "Устойчив, так как сама поверхность для рисования изготовлена из толстой фанеры, что утяжеляет конструкцию и делает ее более устойчивой. \n" +
            "Каркас – сосна, обработанная антисептиком. Антисептик дает полупрозрачное покрытие. Текстура дерева сохранена. Можем полностью перекрыть текстуру цветной эмалью. Для этого перейдите в раздел «Индивидуальные заказы». Сама грифельная поверхность может быть другим цветом – красным, синим, зеленым и т.д.\n" +
            "Рама из бруса 40*30мм. Стандартный цвет – венге, слоновая кость. Другие цвета +350р\n",
        src: Picture17
    },
    {
        // id: 1,
        name: "Штендер меловой рекламный двухсторонний, 52*80 см",
        series: "",
        TextAnnouncement: "Штендер рекламный, двухсторонний для письма и рисования мелом",
        price: 3100,
        totalPrice:3100,
        count: 1,
        about: "Штендер рекламный, двухсторонний для письма и рисования мелом, изготовлен из массива дерева обработанного специальным составом, сбоку имеется фиксатор для устойчивости-цепочка. Применяется в качестве рекламной стойки выносного типа. Очень легкая, устойчивая и удобная в работе конструкция, позволяет оперативно менять информацию о ценах, акциях, меню, распродажах.\n" +
            "Также, хорошая идея для детской комнаты. Отлично сыграет роль детского мольберта.\n" +
            "Устойчив, так как сама поверхность для рисования изготовлена из толстой фанеры, что утяжеляет конструкцию и делает ее более устойчивой. \n" +
            "Каркас – сосна, обработанная антисептиком. Антисептик дает полупрозрачное покрытие. Текстура дерева сохранена. Можем полностью перекрыть текстуру цветной эмалью. Для этого перейдите в раздел «Индивидуальные заказы». Сама грифельная поверхность может быть другим цветом – красным, синим, зеленым и т.д.\n" +
            "Рама из бруса 40*30мм. Стандартный цвет – венге, слоновая кость. Другие цвета +350р\n",
        src: Picture18
    }
];

const Main = () => {

    const [basket, setBasket] = useState([])   //Корзина


    return (<>
            <Header/>

            <NavComponent basket={basket} setBasket={setBasket}
                          Serving={Serving} ServingDishes={ServingDishes}
                          TeaStationsTrays={TeaStationsTrays}
                          EndBoards={EndBoards}
                          PriceTags={PriceTags}
                          Furniture={Furniture}
                          SignsTags={SignsTags}
            />
            <IndividualOrders/>
            <Basket basket={basket} setBasket={setBasket}/>
            <BlogNavComponent/>
            <AboutUs/>
            <Contacts/>
        </>
    )
}
export default Main