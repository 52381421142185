import Container from "react-bootstrap/Container";

const IndividualOrders = () => {
    return (
        <Container className="p-0 pt-5">
            <h4 id='individualOrders'>ИНДИВИДУЛЬНЫЕ ЗАКАЗЫ</h4>
            <p>Мы готовы воплотить в жизнь Ваши идеи. Необычная форма, обжиг, брашировка, нестандартный размер? Любые
                Ваши пожелания отправьте на нашу почту kargowood@yandex.ru. Если у Вас есть примерное фото желаемого –
                приложите его к письму.</p>
            <p>БОНУС! Для индивидуальных заказов сделаем ПРОБНИК в подарок. Как это работает?
                Вы оплачиваете изначально пробный вариант, делаем и отправляем его Вам в одном экземпляре.
                1)	Если все устраивает – делаем тираж, из общей суммы счета вычитаем стоимость пробного варианта;
                2)	Вас что-то не устроило, вносим правки, делаем тираж, из общей суммы счета вычитаем стоимость пробного варианта.
            </p>
        </Container>
    )
}

export default IndividualOrders